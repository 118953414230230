import React, { FC, useEffect } from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import { useVisitorBi, useEnvironment } from '@wix/yoshi-flow-editor';

import settingsParams from '../../../settingsParams';
import { classes } from './ListFilter.st.css';
import { TabsFilter } from './TabsFilter/TabsFilter';
import { TagsFilter } from './TagsFilter/TagsFilter';
import { DropdownFilter } from './DropdownFilter/DropdownFilter';
import { FilterLayoutOptions } from '../../../../../types/types';
import { DataHooks } from './consts';
import { useWidgetViewModel } from '../../hooks/useWidgetViewModel';
import { ALL_SERVICES } from '../../../../../consts';

function getFilterComponent({ layout }: { layout: FilterLayoutOptions }) {
  switch (layout) {
    case FilterLayoutOptions.DROPDOWN:
      return DropdownFilter;
    case FilterLayoutOptions.TAGS:
      return TagsFilter;
    case FilterLayoutOptions.TABS:
      return TabsFilter;
  }
}

export const ListFilter: FC = () => {
  const settings = useSettings();
  const layout = settings.get(settingsParams.filterLayout);
  const FilterComponent = getFilterComponent({ layout });
  return (
    <div data-hook={DataHooks.WRAPPER} className={classes.root}>
      <FilterComponent />
    </div>
  );
};
